import { Injectable } from "@angular/core";
import { ProductsService } from "@app/core/services/products.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromCategoryFilter from "../../store";
import * as CategoryFilterActions from "../actions/category-filter.actions";
import * as CategoryFilterSelectors from "../selectors/category-filter.selectors";

@Injectable()
export class CategoryFilterEffects {
  loadCategoryFilterList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryFilterActions.loadCategoryFilterList),
      concatLatestFrom(() => [
        this.store.pipe(
          select(CategoryFilterSelectors.selectAllCategoryFilterList()),
        ),
      ]),
      switchMap(([_, allCategoryFilterList]) => {
        if (allCategoryFilterList.length) {
          return of(
            CategoryFilterActions.loadCategoryFilterListSuccess({
              categoryFilterList: allCategoryFilterList,
            }),
          );
        }

        return this.productsService.loadProductGroups().pipe(
          map((response) =>
            CategoryFilterActions.loadCategoryFilterListSuccess({
              categoryFilterList: response,
            }),
          ),
          catchError((error) =>
            of(CategoryFilterActions.loadCategoryFilterListFailure({ error })),
          ),
        );
      }),
    ),
  );

  applyCategoryFilterList$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(CategoryFilterActions.applyCategoryFilterList),
      concatLatestFrom(() => [
        this.store.pipe(select(CategoryFilterSelectors.selectActivePage)),
      ]),
      map(([_, activePage]): any => {
        const pageNameList = [
          "products",
          "recipe-create",
          "ration-report-day-page",
        ];

        if (activePage === pageNameList[0]) {
          return CategoryFilterActions.applyCategoryFilterListProducts();
        }

        if (activePage === pageNameList[1]) {
          return CategoryFilterActions.applyCategoryFilterListRecipeCreate();
        }

        if (activePage === pageNameList[2]) {
          return CategoryFilterActions.applyCategoryFilterListRation();
        }
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromCategoryFilter.State>,
    private productsService: ProductsService,
  ) {}
}
