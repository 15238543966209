import { ProductCategoryFilter } from "@app/shared/models/product-category-filter.model";
import * as fromRoot from "@app/store";
import { createSelector } from "@ngrx/store";
import { selectCategoryFilterState } from "../reducers";
import * as fromCategoryFilter from "../reducers/category-filter.reducer";

export const selectActivePage = fromRoot.selectRouterParamData("page");

export const selectCategoryFilterListIsLoading = () =>
  createSelector(
    selectCategoryFilterState(),
    fromCategoryFilter.selectIsLoading,
  );

export const selectAllCategoryFilterList = () =>
  createSelector(selectCategoryFilterState(), fromCategoryFilter.selectAll);

export const selectAppliedCategoryFilterList = () =>
  createSelector(
    selectAllCategoryFilterList(),
    (categoryFilterList: ProductCategoryFilter[]) =>
      categoryFilterList.filter(
        (categoryFilter: ProductCategoryFilter) => categoryFilter.applied,
      ),
  );

export const selectAppliedCategoryFilterIdList = () =>
  createSelector(
    selectAppliedCategoryFilterList(),
    (categoryFilterList: ProductCategoryFilter[]) =>
      categoryFilterList.map(
        (categoryFilter: ProductCategoryFilter) => categoryFilter.id,
      ),
  );

// TODO refactor
export const selectCategoryFilterPartitionList = () =>
  createSelector(
    selectAllCategoryFilterList(),
    (categoryFilterList: ProductCategoryFilter[]) => {
      const numColumns = 3;
      const columnHeight = Math.ceil(categoryFilterList.length / numColumns);

      return [...Array(numColumns).keys()].reduce(
        (
          categoryFilterPartitionList: ProductCategoryFilter[][],
          partitionStartIndex: number,
        ) => {
          const startIndex = partitionStartIndex * columnHeight;
          categoryFilterPartitionList.push(
            categoryFilterList.slice(startIndex, startIndex + columnHeight),
          );

          return categoryFilterPartitionList;
        },
        [],
      );
    },
  );
