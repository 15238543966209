import { createAction, props } from "@ngrx/store";

export const loadCategoryFilterList = createAction(
  "[Category Filter / API] Load Category List",
);
export const loadCategoryFilterListSuccess = createAction(
  "[Category Filter / API] Load Category List Success",
  props<{ categoryFilterList: any[] }>(),
);
export const loadCategoryFilterListFailure = createAction(
  "[Category Filter / API] Load Category List Failure",
  props<{ error: any }>(),
);

export const toggleCategoryFilter = createAction(
  "[Category Filter] Toggle Category Filter",
  props<{ categoryFilterId: number }>(),
);

export const applyCategoryFilterList = createAction(
  "[Category Filter / API] Apply Category Filters",
);
export const applyCategoryFilterListProducts = createAction(
  "[Category Filter / API] Apply Category Filter List Products",
);
export const applyCategoryFilterListRation = createAction(
  "[Category Filter / API] Apply Category Filter List Ration",
);
export const applyCategoryFilterListRecipeCreate = createAction(
  "[Category Filter / API] Apply Category Filter List Recipe Create",
);

export const resetCategoryFilterList = createAction(
  "[Category Filter] Reset Category Filters",
);

export const resetLastAppliedCategoryFilterList = createAction(
  "[Category Filter] Reset Last Applied Category Filters",
);

export const restoreCategoryFilterList = createAction(
  "[Category Filter] Restore Category Filters",
);
