import * as fromRoot from "@app/store";
import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import * as fromCategoryFilter from "./category-filter.reducer";

export const categoryFilterFeatureKey = "category-filter";

export interface State extends fromRoot.State {
  [fromCategoryFilter.categoryFilterFeatureKey]: fromCategoryFilter.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    [fromCategoryFilter.categoryFilterFeatureKey]: fromCategoryFilter.reducer,
  })(state, action);
}

export const getCategoryFilterState = createFeatureSelector<State>(
  categoryFilterFeatureKey,
);

export const selectCategoryFilterState = () =>
  createSelector(
    getCategoryFilterState,
    (state: State) => state[fromCategoryFilter.categoryFilterFeatureKey],
  );
