import { ProductCategoryFilter } from "@app/shared/models/product-category-filter.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import * as CategoryFilterActions from "../actions/category-filter.actions";

export const categoryFilterFeatureKey = "category-filter";

export interface State extends EntityState<ProductCategoryFilter> {
  isLoading: boolean;
  lastAppliedCategoryFilterIdList: Record<string, number>;
}

export const adapter: EntityAdapter<ProductCategoryFilter> =
  createEntityAdapter<ProductCategoryFilter>({
    selectId: (categoryFilter: any) => categoryFilter.id,
    sortComparer: false,
  });

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  error: null,
  lastAppliedCategoryFilterIdList: {},
});

export const reducer = createReducer(
  initialState,

  on(CategoryFilterActions.loadCategoryFilterList, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    CategoryFilterActions.loadCategoryFilterListSuccess,
    (state, { categoryFilterList }) => ({
      ...adapter.setAll(
        categoryFilterList.map((categoryFilter) => ({
          ...categoryFilter,
          applied: !!state.lastAppliedCategoryFilterIdList[categoryFilter.id],
        })),
        state,
      ),
      isLoading: false,
    }),
  ),
  on(
    CategoryFilterActions.loadCategoryFilterListFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error,
    }),
  ),

  on(
    CategoryFilterActions.toggleCategoryFilter,
    (state, { categoryFilterId: id }) => ({
      ...adapter.mapOne(
        {
          id,
          map: (categoryFilter: ProductCategoryFilter) => ({
            ...categoryFilter,
            applied: !categoryFilter.applied,
          }),
        },
        state,
      ),
    }),
  ),

  on(CategoryFilterActions.resetCategoryFilterList, (state) =>
    adapter.map(
      (categoryFilter: ProductCategoryFilter) => ({
        ...categoryFilter,
        applied: false,
      }),
      state,
    ),
  ),

  on(CategoryFilterActions.applyCategoryFilterList, (state: any) => ({
    ...state,
    lastAppliedCategoryFilterIdList: Object.keys(state.entities).reduce(
      (appliedCategoryFilterIdList: any, categoryFilterId) => {
        const categoryFilter = state.entities[categoryFilterId];
        if (categoryFilter.applied) {
          appliedCategoryFilterIdList[categoryFilter.id] = categoryFilter.id;
        }

        return appliedCategoryFilterIdList;
      },
      {},
    ),
  })),

  on(CategoryFilterActions.restoreCategoryFilterList, (state) =>
    adapter.map(
      (categoryFilter: ProductCategoryFilter) => ({
        ...categoryFilter,
        applied: !!state.lastAppliedCategoryFilterIdList[categoryFilter.id],
      }),
      state,
    ),
  ),

  on(CategoryFilterActions.resetLastAppliedCategoryFilterList, (state) => ({
    ...state,
    lastAppliedCategoryFilterIdList: {},
  })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
